import { useState, useEffect, useMemo } from 'react'
import { Moon, Sun, Apple, UserPlus, Users, FolderPlus, UserCheck, ClipboardList, ChevronLeft, ChevronRight, Mail, PlaySquare } from 'lucide-react'
import { motion } from 'framer-motion'
import { Button } from "./ui/button"

export default function LandingPage() {
  const [darkMode, setDarkMode] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [loadedImages, setLoadedImages] = useState({})
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // Wrap steps array in useMemo
  const steps = useMemo(() => [
    {
      title: "Register",
      description: "Create your account in minutes",
      image: `${process.env.PUBLIC_URL}/register.JPEG`,
      icon: <UserPlus className="w-6 h-6" />
    },
    {
      title: "Create Team",
      description: "Add and manage team members",
      image: `${process.env.PUBLIC_URL}/create-team.JPEG`,
      icon: <Users className="w-6 h-6" />
    },
    {
      title: "Create Project",
      description: "Set up new projects effortlessly",
      image: `${process.env.PUBLIC_URL}/create-project.JPEG`,
      icon: <FolderPlus className="w-6 h-6" />
    },
    {
      title: "Assign Team",
      description: "Allocate team members to projects",
      image: `${process.env.PUBLIC_URL}/assign-team.JPEG`,
      icon: <UserCheck className="w-6 h-6" />
    },
    {
      title: "Create Tasks",
      description: "Manage project tasks efficiently",
      image: `${process.env.PUBLIC_URL}/create-tasks.JPEG`,
      icon: <ClipboardList className="w-6 h-6" />
    }
  ], [])

  // Dark mode effect
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [darkMode])

  // Step rotation effect
  useEffect(() => {
    const handleAutoSlide = () => {
      // Only auto-slide on desktop
      if (window.innerWidth >= 768) {  // 768px is the md breakpoint in Tailwind
        const interval = setInterval(() => {
          setActiveStep((prev) => (prev + 1) % 5)
        }, 3000)
        return () => clearInterval(interval)
      }
    }

    const interval = handleAutoSlide()
    return () => interval && clearInterval(interval)
  }, [])

  // Add manual navigation functions
  const handlePrevStep = () => {
    setActiveStep((prev) => (prev - 1 + 5) % 5)
  }

  const handleNextStep = () => {
    setActiveStep((prev) => (prev + 1) % 5)
  }

  // Image preloading effect
  useEffect(() => {
    const preloadImages = async () => {
      const loadImage = (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(src);
          img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
          img.src = src;
        });
      };

      try {
        const results = await Promise.allSettled(
          steps.map(step => loadImage(step.image))
        );
        
        const loadedImageMap = results.reduce((acc, result, index) => {
          if (result.status === 'fulfilled') {
            acc[steps[index].image] = true;
          }
          return acc;
        }, {});

        setLoadedImages(loadedImageMap);
      } catch (error) {
        console.error('Error preloading images:', error);
      }
    };

    preloadImages();
  }, [steps]);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX)
  }

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > 50
    const isRightSwipe = distance < -50

    if (isLeftSwipe) {
      handleNextStep()
    }

    if (isRightSwipe) {
      handlePrevStep()
    }

    setTouchStart(null)
    setTouchEnd(null)
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#F8FFF9] to-[#E8F5E9] dark:from-[#0A1F0D] dark:to-[#1B5E20] transition-colors duration-300">
      {/* Header */}
      <header className="fixed w-full top-0 z-50 bg-white/80 dark:bg-[#0A1F0D]/80 backdrop-blur-sm border-b border-[#2E7D32]/10">
        <nav className="container mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <a href="/" className="flex items-center gap-2">
              <img
                src="/logo.png"
                alt="TaskMi Logo"
                className="w-8 h-8"
              />
              <span className="text-xl font-semibold text-[#1B5E20] dark:text-[#4CAF50]">TaskMi</span>
            </a>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setDarkMode(!darkMode)}
              className="text-[#2E7D32] dark:text-[#4CAF50]"
            >
              {darkMode ? <Sun className="h-[1.2rem] w-[1.2rem]" /> : <Moon className="h-[1.2rem] w-[1.2rem]" />}
            </Button>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="relative pt-32 pb-20">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-3xl mx-auto mb-16"
          >
            <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold text-[#1B5E20] dark:text-[#C8E6C9] mb-6">
              Empower Your Team, Boost Productivity
            </h1>
            <p className="text-lg sm:text-xl text-[#2E7D32] dark:text-[#A5D6A7] mb-8">
              The ultimate task management solution for small businesses and teams of all sizes
            </p>
          </motion.div>

          {/* Promotional Text */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-center max-w-4xl mx-auto mb-16"
          >
            <h2 className="text-2xl sm:text-3xl font-bold text-[#1B5E20] dark:text-[#C8E6C9] mb-8">
              Why Choose TaskMi?
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="p-6 rounded-2xl bg-white/50 dark:bg-[#1B5E20]/20 backdrop-blur-sm">
                <div className="w-12 h-12 mx-auto mb-4 rounded-full bg-[#2E7D32]/10 dark:bg-[#4CAF50]/20 flex items-center justify-center">
                  <svg className="w-6 h-6 text-[#2E7D32] dark:text-[#4CAF50]" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-[#2E7D32] dark:text-[#4CAF50] mb-2">Instant Usability</h3>
                <p className="text-[#1B5E20] dark:text-[#A5D6A7]">No training required, perfect for teams with limited digital skills</p>
              </div>
              <div className="p-6 rounded-2xl bg-white/50 dark:bg-[#1B5E20]/20 backdrop-blur-sm">
                <div className="w-12 h-12 mx-auto mb-4 rounded-full bg-[#2E7D32]/10 dark:bg-[#4CAF50]/20 flex items-center justify-center">
                  <svg className="w-6 h-6 text-[#2E7D32] dark:text-[#4CAF50]" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-[#2E7D32] dark:text-[#4CAF50] mb-2">Cost-Effective</h3>
                <p className="text-[#1B5E20] dark:text-[#A5D6A7]">High-value features at a fraction of the cost of complex platforms</p>
              </div>
              <div className="p-6 rounded-2xl bg-white/50 dark:bg-[#1B5E20]/20 backdrop-blur-sm">
                <div className="w-12 h-12 mx-auto mb-4 rounded-full bg-[#2E7D32]/10 dark:bg-[#4CAF50]/20 flex items-center justify-center">
                  <svg className="w-6 h-6 text-[#2E7D32] dark:text-[#4CAF50]" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold text-[#2E7D32] dark:text-[#4CAF50] mb-2">Enhanced Efficiency</h3>
                <p className="text-[#1B5E20] dark:text-[#A5D6A7]">Reduce meetings and calls, increase transparency and productivity</p>
              </div>
            </div>
          </motion.div>

          {/* App Journey Showcase */}
          <div className="max-w-6xl mx-auto mb-24">
            <h2 className="text-2xl sm:text-3xl font-bold text-center text-[#1B5E20] dark:text-[#C8E6C9] mb-12">
              Get Started in 5 Simple Steps
            </h2>
            
            {/* Desktop Layout */}
            <div className="hidden md:grid md:grid-cols-2 gap-12 items-center">
              {/* Original desktop layout */}
              <motion.div 
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                className="space-y-8"
              >
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`flex items-start gap-3 p-4 md:p-6 rounded-xl transition-all duration-300 ${
                      activeStep === index 
                        ? 'bg-white dark:bg-[#1B5E20]/30 shadow-lg scale-[1.02]' 
                        : 'opacity-70'
                    }`}
                  >
                    <div className="rounded-full bg-[#2E7D32] dark:bg-[#4CAF50] p-2 md:p-3 text-white shrink-0">
                      {step.icon}
                    </div>
                    <div className="min-w-0">
                      <h3 className="text-lg md:text-xl font-semibold text-[#1B5E20] dark:text-[#C8E6C9] mb-1 md:mb-2">
                        {step.title}
                      </h3>
                      <p className="text-sm md:text-base text-[#2E7D32] dark:text-[#A5D6A7] line-clamp-2">
                        {step.description}
                      </p>
                    </div>
                  </div>
                ))}
              </motion.div>
              
              {/* Original iPhone frame for desktop */}
              <motion.div>
                <div className="relative flex justify-center order-1 md:order-2 mb-8 md:mb-0">
                  <div className="absolute inset-0 bg-gradient-to-r from-[#2E7D32]/5 to-[#66BB6A]/5 dark:from-[#1B5E20]/5 dark:to-[#388E3C]/5 rounded-3xl transform -rotate-1"></div>
                  <motion.div
                    key={activeStep}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className="relative z-10 w-full max-w-[220px] md:max-w-[240px] lg:max-w-[280px]"
                  >
                    {/* iPhone Frame */}
                    <div className="relative">
                      {/* Background gradient for the frame */}
                      <div 
                        className="absolute inset-0 bg-gradient-to-br from-[#2E7D32]/90 to-[#66BB6A]/90 dark:from-[#1B5E20]/90 dark:to-[#388E3C]/90 rounded-[2.5rem]"
                        style={{ transform: 'rotate(6deg)' }}
                      />
                      
                      {/* iPhone body */}
                      <div className="relative z-10 bg-black rounded-[2.5rem] p-2.5 shadow-2xl">
                        {/* iPhone Notch */}
                        <div className="absolute top-0 left-1/2 -translate-x-1/2 h-5 w-32 bg-black rounded-b-3xl z-20" />
                        
                        {/* Screenshot container */}
                        <div className="relative aspect-[9/19] rounded-[2rem] overflow-hidden bg-[#ededed]">
                          {loadedImages[steps[activeStep].image] && (
                            <img
                              key={steps[activeStep].image}
                              src={steps[activeStep].image}
                              alt={`${steps[activeStep].title} Interface`}
                              className="w-full h-full object-contain"
                            />
                          )}
                        </div>
                        
                        {/* Home Indicator */}
                        <div className="absolute bottom-1 left-1/2 -translate-x-1/2 h-1 w-24 bg-white/80 rounded-full" />
                      </div>
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            </div>

            {/* Mobile Layout */}
            <div className="md:hidden">
              <motion.div
                key={activeStep}
                initial={{ opacity: 0, x: activeStep % 2 === 0 ? -20 : 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col items-center gap-6"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                {/* Combined Card Container */}
                <div className="w-full bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-xl shadow-sm">
                  {/* Step Info */}
                  <div className="mb-8 text-center">
                    <div className="flex flex-col items-center gap-3 mb-3">
                      <div className="rounded-full bg-[#2E7D32] dark:bg-[#4CAF50] p-2 text-white">
                        {steps[activeStep].icon}
                      </div>
                      <h3 className="text-lg sm:text-xl font-semibold text-[#1B5E20] dark:text-[#C8E6C9]">
                        {steps[activeStep].title}
                      </h3>
                    </div>
                    <p className="text-sm sm:text-base text-[#2E7D32] dark:text-[#A5D6A7]">
                      {steps[activeStep].description}
                    </p>
                  </div>

                  {/* iPhone Frame */}
                  <div className="relative w-full max-w-[220px] mx-auto">
                    <div className="absolute inset-0 bg-gradient-to-br from-[#2E7D32]/90 to-[#66BB6A]/90 dark:from-[#1B5E20]/90 dark:to-[#388E3C]/90 rounded-[2.5rem]" style={{ transform: 'rotate(6deg)' }} />
                    <div className="relative z-10 bg-black rounded-[2.5rem] p-2.5 shadow-2xl">
                      <div className="absolute top-0 left-1/2 -translate-x-1/2 h-5 w-32 bg-black rounded-b-3xl z-20" />
                      <div className="relative aspect-[9/19] rounded-[2rem] overflow-hidden bg-[#ededed]">
                        {loadedImages[steps[activeStep].image] && (
                          <img
                            key={steps[activeStep].image}
                            src={steps[activeStep].image}
                            alt={`${steps[activeStep].title} Interface`}
                            className="w-full h-full object-contain"
                          />
                        )}
                      </div>
                      <div className="absolute bottom-1 left-1/2 -translate-x-1/2 h-1 w-24 bg-white/80 rounded-full" />
                    </div>
                  </div>
                </div>

                {/* Navigation Controls */}
                <div className="flex items-center justify-between w-full px-4">
                  <button
                    onClick={handlePrevStep}
                    className="p-2 text-[#2E7D32] dark:text-[#4CAF50] hover:bg-white/10 rounded-full transition-colors"
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>

                  {/* Step Indicators */}
                  <div className="flex gap-2">
                    {steps.map((_, index) => (
                      <div
                        key={index}
                        className={`h-2 rounded-full transition-all duration-300 ${
                          index === activeStep 
                            ? 'w-8 bg-[#2E7D32] dark:bg-[#4CAF50]' 
                            : 'w-2 bg-[#2E7D32]/30 dark:bg-[#4CAF50]/30'
                        }`}
                      />
                    ))}
                  </div>

                  <button
                    onClick={handleNextStep}
                    className="p-2 text-[#2E7D32] dark:text-[#4CAF50] hover:bg-white/10 rounded-full transition-colors"
                  >
                    <ChevronRight className="w-6 h-6" />
                  </button>
                </div>
              </motion.div>
            </div>
          </div>

          {/* QR Code section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-2xl mx-auto mb-24"
          >
            <h2 className="text-2xl sm:text-3xl font-bold text-[#1B5E20] dark:text-[#C8E6C9] mb-4">
              Available Soon
            </h2>
            <p className="text-base sm:text-lg text-[#2E7D32] dark:text-[#A5D6A7] mb-8">
              Download on your preferred platform
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* App Store QR */}
              <div className="flex items-center justify-center gap-4">
                <div className="bg-white/90 dark:bg-white/95 p-2 sm:p-3 rounded-xl shadow-lg">
                  <img 
                    src="/qr-code.png"
                    alt="App Store QR Code" 
                    className="w-16 sm:w-24 h-16 sm:h-24"
                  />
                </div>
                <Button 
                  variant="outline" 
                  size="lg"
                  className="border-[#2E7D32] dark:border-[#4CAF50] text-[#2E7D32] dark:text-[#4CAF50] hover:bg-[#2E7D32] hover:text-white dark:hover:bg-[#4CAF50] dark:hover:text-white group rounded-full"
                >
                  <Apple className="mr-2 h-5 w-5" />
                  App Store
                </Button>
              </div>

              {/* Play Store QR */}
              <div className="flex items-center justify-center gap-4">
                <div className="bg-white/90 dark:bg-white/95 p-2 sm:p-3 rounded-xl shadow-lg">
                  <img 
                    src="/qr-code.png"
                    alt="Play Store QR Code" 
                    className="w-16 sm:w-24 h-16 sm:h-24"
                  />
                </div>
                <Button 
                  variant="outline" 
                  size="lg"
                  className="border-[#2E7D32] dark:border-[#4CAF50] text-[#2E7D32] dark:text-[#4CAF50] hover:bg-[#2E7D32] hover:text-white dark:hover:bg-[#4CAF50] dark:hover:text-white group rounded-full"
                >
                  <PlaySquare className="mr-2 h-5 w-5" />
                  Play Store
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Footer with Wave */}
      <footer className="relative bg-[#2E7D32] dark:bg-[#1B5E20] text-white -mt-1">
        {/* Wave SVG */}
        <div className="absolute bottom-full left-0 right-0 overflow-hidden">
          <svg 
            viewBox="0 0 1440 120" 
            className="w-full h-auto fill-[#2E7D32] dark:fill-[#1B5E20] block"
            preserveAspectRatio="none"
          >
            <path 
              d="M0,32L60,42.7C120,53,240,75,360,80C480,85,600,75,720,58.7C840,43,960,21,1080,16C1200,11,1320,21,1380,26.7L1440,32L1440,120L1380,120C1320,120,1200,120,1080,120C960,120,840,120,720,120C600,120,480,120,360,120C240,120,120,120,60,120L0,120Z"
              className="animate-wave"
            >
            </path>
          </svg>
        </div>

        {/* Footer Content */}
        <div className="container mx-auto px-4 py-8 text-center">
          <p>&copy; {new Date().getFullYear()} TaskMi. All rights reserved.</p>
          <div className="mt-4 flex justify-center items-center gap-6">
            <a href="/privacy" className="hover:text-[#A5D6A7] transition-colors">
              Privacy Policy
            </a>
            <a 
              href="mailto:admin@taskmi.app"
              className="inline-flex items-center gap-2 hover:text-[#A5D6A7] transition-colors"
            >
              <Mail className="h-5 w-5" />
              admin@taskmi.app
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}