import React, { useState, useEffect } from 'react';
import { 
  Mail, 
  ArrowLeft, 
  User, 
  Mail as MailIcon, 
  Key, 
  MapPin, 
  AppWindow, 
  Shield, 
  Bell, 
  LineChart, 
  Headphones, 
  UserCheck, 
  Scale, 
  Building, 
  FileEdit, 
  Trash2, 
  BellOff,
  Moon,
  Sun
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from "./ui/button";

export default function PrivacyPolicy() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [darkMode])

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#F8FFF9] to-[#E8F5E9] dark:from-[#0A1F0D] dark:to-[#1B5E20] transition-colors duration-300">
      {/* Header */}
      <header className="fixed w-full top-0 z-50 bg-white/80 dark:bg-[#0A1F0D]/80 backdrop-blur-sm border-b border-[#2E7D32]/10">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link 
            to="/"
            className="inline-flex items-center gap-2 text-[#2E7D32] dark:text-[#4CAF50] hover:text-[#1B5E20] dark:hover:text-[#A5D6A7] transition-colors"
          >
            <ArrowLeft className="h-5 w-5" />
            Back to Home
          </Link>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setDarkMode(!darkMode)}
            className="text-[#2E7D32] dark:text-[#4CAF50]"
          >
            {darkMode ? <Sun className="h-[1.2rem] w-[1.2rem]" /> : <Moon className="h-[1.2rem] w-[1.2rem]" />}
          </Button>
        </div>
      </header>

      <div className="container mx-auto px-4 pt-24 pb-16">
        <div className="max-w-4xl mx-auto">
          {/* Title Section */}
          <div className="text-center mb-12">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-[#1B5E20] dark:text-[#C8E6C9] mb-4">
              Privacy Policy
            </h1>
            <p className="text-base sm:text-lg text-[#2E7D32] dark:text-[#A5D6A7]">
              Last Updated: 14/11/2024
            </p>
          </div>

          {/* Content */}
          <div className="space-y-12 bg-white/90 dark:bg-[#1B5E20]/20 backdrop-blur-md rounded-2xl p-6 sm:p-8 md:p-12 shadow-xl">
            {/* Introduction */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-[#2E7D32] dark:text-[#4CAF50] mb-4">
                1. Introduction
              </h2>
              <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7] leading-relaxed">
                Welcome to TaskMi. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application ("App").
              </p>
            </section>

            {/* Information Collection */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-[#2E7D32] dark:text-[#4CAF50] mb-4">
                2. Information We Collect
              </h2>
              <div className="space-y-6">
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold text-[#2E7D32] dark:text-[#4CAF50] mb-4">
                    a. Personal Information
                  </h3>
                  <div className="grid sm:grid-cols-2 gap-4">
                    <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-4 sm:p-6 rounded-lg shadow-sm">
                      <div className="flex items-center gap-3 mb-3">
                        <User className="h-4 w-4 sm:h-5 sm:w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                        <h4 className="text-base sm:text-lg font-semibold text-[#2E7D32] dark:text-[#4CAF50]">Name</h4>
                      </div>
                      <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7]">Collected when users create an account or update their profile.</p>
                    </div>
                    <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-4 sm:p-6 rounded-lg shadow-sm">
                      <div className="flex items-center gap-3 mb-3">
                        <MailIcon className="h-4 w-4 sm:h-5 sm:w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                        <h4 className="text-base sm:text-lg font-semibold text-[#2E7D32] dark:text-[#4CAF50]">Email Address</h4>
                      </div>
                      <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7]">Used for account authentication, communication, and password recovery.</p>
                    </div>
                    <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-4 sm:p-6 rounded-lg shadow-sm">
                      <div className="flex items-center gap-3 mb-3">
                        <Key className="h-4 w-4 sm:h-5 sm:w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                        <h4 className="text-base sm:text-lg font-semibold text-[#2E7D32] dark:text-[#4CAF50]">User Credentials</h4>
                      </div>
                      <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7]">Passwords and other security information for account access.</p>
                    </div>
                    <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-4 sm:p-6 rounded-lg shadow-sm">
                      <div className="flex items-center gap-3 mb-3">
                        <MapPin className="h-4 w-4 sm:h-5 sm:w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                        <h4 className="text-base sm:text-lg font-semibold text-[#2E7D32] dark:text-[#4CAF50]">Project Addresses</h4>
                      </div>
                      <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7]">Physical addresses of projects entered by users.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* How We Use Your Information */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-[#2E7D32] dark:text-[#4CAF50] mb-4">
                3. How We Use Your Information
              </h2>
              <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7] mb-4">
                We use the collected data for the following purposes:
              </p>
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <AppWindow className="h-5 w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                    <h4 className="font-semibold text-[#2E7D32] dark:text-[#4CAF50]">App Functionality</h4>
                  </div>
                  <p className="text-[#1B5E20] dark:text-[#A5D6A7]">To provide and maintain our App's core features, including project and task management.</p>
                </div>
                <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <Shield className="h-5 w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                    <h4 className="font-semibold text-[#2E7D32] dark:text-[#4CAF50]">User Authentication</h4>
                  </div>
                  <p className="text-[#1B5E20] dark:text-[#A5D6A7]">To verify your identity when you log in.</p>
                </div>
                <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <Bell className="h-5 w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                    <h4 className="font-semibold text-[#2E7D32] dark:text-[#4CAF50]">Communication</h4>
                  </div>
                  <p className="text-[#1B5E20] dark:text-[#A5D6A7]">To send you updates, security alerts, and support messages.</p>
                </div>
                <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <LineChart className="h-5 w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                    <h4 className="font-semibold text-[#2E7D32] dark:text-[#4CAF50]">Improvement</h4>
                  </div>
                  <p className="text-[#1B5E20] dark:text-[#A5D6A7]">To analyze usage patterns to enhance user experience and App performance.</p>
                </div>
                <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <Headphones className="h-5 w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                    <h4 className="font-semibold text-[#2E7D32] dark:text-[#4CAF50]">Customer Support</h4>
                  </div>
                  <p className="text-[#1B5E20] dark:text-[#A5D6A7]">To address your inquiries and provide assistance.</p>
                </div>
              </div>
            </section>

            {/* Disclosure of Your Information */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-[#2E7D32] dark:text-[#4CAF50] mb-4">
                4. Disclosure of Your Information
              </h2>
              <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7] mb-4">
                We do not sell or rent your personal information to third parties. We may share your information in the following situations:
              </p>
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <UserCheck className="h-5 w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                    <h4 className="font-semibold text-[#2E7D32] dark:text-[#4CAF50]">With Your Consent</h4>
                  </div>
                  <p className="text-[#1B5E20] dark:text-[#A5D6A7]">We may disclose your information with your explicit consent.</p>
                </div>
                <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <Scale className="h-5 w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                    <h4 className="font-semibold text-[#2E7D32] dark:text-[#4CAF50]">Legal Obligations</h4>
                  </div>
                  <p className="text-[#1B5E20] dark:text-[#A5D6A7]">If required by law or in response to valid requests by public authorities.</p>
                </div>
                <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <Building className="h-5 w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                    <h4 className="font-semibold text-[#2E7D32] dark:text-[#4CAF50]">Business Transfers</h4>
                  </div>
                  <p className="text-[#1B5E20] dark:text-[#A5D6A7]">In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition.</p>
                </div>
              </div>
            </section>

            {/* Data Security */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-[#2E7D32] dark:text-[#4CAF50] mb-4">
                5. Data Security
              </h2>
              <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7]">
                We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. These measures include encryption, access controls, and secure data storage practices.
              </p>
            </section>

            {/* Your Choices and Rights */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-[#2E7D32] dark:text-[#4CAF50] mb-4">
                6. Your Choices and Rights
              </h2>
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <FileEdit className="h-5 w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                    <h4 className="font-semibold text-[#2E7D32] dark:text-[#4CAF50]">Access and Correction</h4>
                  </div>
                  <p className="text-[#1B5E20] dark:text-[#A5D6A7]">You can access and update your personal information within the App settings.</p>
                </div>
                <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <Trash2 className="h-5 w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                    <h4 className="font-semibold text-[#2E7D32] dark:text-[#4CAF50]">Account Deletion</h4>
                  </div>
                  <p className="text-[#1B5E20] dark:text-[#A5D6A7]">You may request to delete your account and personal data by contacting us.</p>
                </div>
                <div className="bg-white/50 dark:bg-[#1B5E20]/20 p-6 rounded-lg shadow-sm">
                  <div className="flex items-center gap-3 mb-3">
                    <BellOff className="h-5 w-5 text-[#2E7D32] dark:text-[#4CAF50]" />
                    <h4 className="font-semibold text-[#2E7D32] dark:text-[#4CAF50]">Communication Preferences</h4>
                  </div>
                  <p className="text-[#1B5E20] dark:text-[#A5D6A7]">You can opt-out of receiving promotional communications from us.</p>
                </div>
              </div>
            </section>

            {/* Children's Privacy */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-[#2E7D32] dark:text-[#4CAF50] mb-4">
                7. Children's Privacy
              </h2>
              <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7]">
                Our App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13.
              </p>
            </section>

            {/* International Data Transfers */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-[#2E7D32] dark:text-[#4CAF50] mb-4">
                8. International Data Transfers
              </h2>
              <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7]">
                Your information may be transferred to—and maintained on—servers located outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ.
              </p>
            </section>

            {/* Changes to Privacy Policy */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-[#2E7D32] dark:text-[#4CAF50] mb-4">
                9. Changes to This Privacy Policy
              </h2>
              <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7]">
                We may update our Privacy Policy from time to time. We will notify you of any changes by updating the "Last Updated" date at the top of this Privacy Policy. We encourage you to review this Privacy Policy periodically.
              </p>
            </section>

            {/* Contact Section */}
            <section className="mt-16">
              <h2 className="text-xl sm:text-2xl font-bold text-[#2E7D32] dark:text-[#4CAF50] mb-4">
                10. Contact Us
              </h2>
              <p className="text-sm sm:text-base text-[#1B5E20] dark:text-[#A5D6A7] mb-4">
                If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:
              </p>
              <a 
                href="mailto:admin@taskmi.app"
                className="inline-flex items-center gap-2 text-[#2E7D32] dark:text-[#4CAF50] hover:text-[#1B5E20] dark:hover:text-[#A5D6A7] transition-colors bg-[#E8F5E9] dark:bg-[#1B5E20] px-4 py-2 rounded-lg text-sm sm:text-base"
              >
                <Mail className="h-4 w-4 sm:h-5 sm:w-5" />
                admin@taskmi.app
              </a>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
} 